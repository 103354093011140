import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import Timer from "../../Common/Timer"

interface ActionCardProps {
  companyId?: string
}

const ActionCard: FC<ActionCardProps> = ({ companyId }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="bg-white rounded shadow">
        <div className="row p-3">
          <div className="mt-1 mb-3 d-grid">
            <p className="p-0 m-0">{t("Next raffle")}</p>
            <div className="display-4 text-primary my-0 mb-4">
              <Timer deadline={"2024-12-31T23:59:59"} />
            </div>
            <button
              className="btn btn-secondary btn-lg mt-2"
              onClick={async () =>
                await navigator.share({
                  title: t("Share"),
                  text: t("Share"),
                  url: window.location.href,
                })
              }
            >
              {/* <i className="fa fa-share me-2"></i> */}
              {t("Share")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActionCard
