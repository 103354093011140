import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"
import NoData from "../../NoData"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"
import { useTranslation } from "react-i18next"

const CompaniesList = ({ companies }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const redirectDetails = id => {
    history.push(Routes.OrganizationDetails.clean + id)
  }

  return (
    <Row>
      {companies && companies.length > 0 ? (
        companies.map((item, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            lg={4}
            className={`mb-6  pb-5 clickableCard`}
            style={{ height: "18.75rem" }}
            onClick={() => {
              redirectDetails(item.id)
            }}
          >
            <div className="shadow bg-white d-flex justify-content-center align-items-center rounded w-100 h-100">
              <img
                src={`${Settings.BasePath}files/${item.logo}`}
                alt={item.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "0.25rem",
                }}
                loading="lazy"
              />
            </div>
            <div className="text-center mt-2">
              <b>{item.name}</b>
            </div>

            <Row>
              <Col className="text-start">
                <small>{t("Accumulated IBBs")}</small>
                <br />
                <div className="">
                  <span className="text-primary">250,000.00</span>
                </div>
              </Col>
              <Col className="text-end">
                <small>{t("Time to next raffle")}</small>
                <br />
                <div className=" text-primary">
                  <Timer deadline={"2024-12-31T23:59:59"} />
                </div>
              </Col>
            </Row>
          </Col>
        ))
      ) : (
        <NoData />
      )}
    </Row>
  )
}

export default CompaniesList
