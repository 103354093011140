import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CausesItem from "../CausesComponents/CausesList/CausesItem"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { useTranslation } from "react-i18next"

import Skeleton from "react-loading-skeleton"
import { useHistory } from "react-router"
import { Routes } from "../../routes"

const PromotedCausesComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  }
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      const request = await API.getAction("charity/list")
      if (request.data.status === "ok") {
        let data = request.data.response
        setRecords(
          data.map((item: any) => {
            let img = `${Settings.BasePath}files/${item.logo}`
            return {
              id: item.id,
              name: item.name,
              img: img,
              progress: 25,
              raised: 50,
              goal: 100,
              location: item.website,
            }
          })
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  if (records.length === 0) return null
  return (
    <Container className="pt-5 bg-white rounded-5 mb-4 mb-md-5">
      <Row className="d-flex justify-content-between align-items-end flex-wrap flex-md-wrap mb-2">
        <Col xs={12} md={10}>
          <h5 className="subtitle">
            {isLoading ? <Skeleton width={200} /> : t("Foundations")}
          </h5>
          <p className="paragraph text-muted fs-4">
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              t("Discover fundraisers inspired by what you care about")
            )}
          </p>
        </Col>
        <Col
          className="d-flex gap-2 justify-content-end d-none"
          style={{ height: "4rem" }}
          md={2}
        >
          {isLoading ? (
            <>
              <Skeleton circle height={40} width={40} />
              <Skeleton circle height={40} width={40} />
            </>
          ) : (
            <>
              <button className="btn px-4 btn-ghost-primary-light d-none">
                <i className="fa-solid fa-chevron-left text-primary"></i>
              </button>
              <button className="btn px-4 btn-ghost-primary-light d-none">
                <i className="fa-solid fa-chevron-right text-primary"></i>
              </button>
            </>
          )}
        </Col>
      </Row>
      {/* desktop view */}
      <Row className="">
        <Col xs={12} md={6} className="d-none d-md-block d-lg-block">
          <div className="d-flex gap-2 flex-wrap justify-md-content-center w-100">
            {isLoading ? (
              <Skeleton height={510} width={600} />
            ) : (
              <CausesItem
                id={records[0].id}
                title={records[0].name}
                img={records[0].img}
                progress={records[0].progress}
                raised={records[0].raised}
                imgFooter={records[0].location}
                customHeight="510"
                customWidth="600"
              />
            )}
          </div>
        </Col>
        <Col xs={12} md={6} className="d-none d-md-block d-lg-block">
          <div className="d-flex gap-2 flex-wrap justify-content-center w-100 flex-md-nowrap flex-xl-wrap">
            {isLoading
              ? [1, 2, 3].map((_, index) => (
                  <Skeleton key={index} height={160} width={250} />
                ))
              : records.slice(1).map((item, index) => {
                  if (index === 0) return
                  return (
                    <CausesItem
                      key={index}
                      id={item.id}
                      title={item.name}
                      img={item.img}
                      customHeight="160"
                      progress={item.progress}
                      raised={item.raised}
                      imgFooter={item.location}
                      customWidth="250"
                    />
                  )
                })}
          </div>
        </Col>
      </Row>
      {/* mobile view */}
      <Row className="d-block d-md-none">
        <Slider {...settings}>
          {records.map((item, index) => {
            if (index === 0) return
            return (
              <div
                onClick={() =>
                  history.push(`${Routes.FoundationDetails.clean}${item.id}`)
                }
                key={index}
                className="mx-2 clickable bg-light p-2 text-truncate"
              >
                <img
                  src={item.img}
                  alt={item.name}
                  style={{
                    height: "160px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  className="img-fluid img-thumbnail"
                />
                <span className="text-primary">{item.name}</span>
              </div>
              // <CausesItem
              //   key={index}
              //   id={item.id}
              //   title={item.name}
              //   img={item.img}
              //   customHeight="160"
              //   progress={item.progress}
              //   raised={item.raised}
              //   imgFooter={item.location}
              //   customWidth="250"
              // />
            )
          })}
        </Slider>
      </Row>
    </Container>
  )
}

export default PromotedCausesComponent
