export interface AboutContentInterface {
  id: number
  img?: string
  title: string
  content?: AboutContentInterface[]
  description?: string
  cta?: string
}

export const MainContentAbout: AboutContentInterface = {
  id: 1,
  img: "https://via.placeholder.com/550x400",
  title: "Helping individuals to offer mutual support",
  description:
    "We are a platform that connects people who need help with people who can help. We are a community of individuals who exchange help and support to help each other. We believe that everyone has something to offer and that we are all stronger together. We are here to help you find the support you need and to offer help to others. Join us today and start making a difference in your community!",
  cta: "Join us",
}

export const SecondAboutContent: AboutContentInterface = {
  id: 1,
  title: "Helps Adds Up",
  content: [
    {
      id: 1,
      img: "https://via.placeholder.com/300x200",
      title: "Find Help",
      description:
        "Find the help you need from people who care. Whether you need a ride to the grocery store, help with your homework, or someone to talk to, we are here to help you find the support you need.",
    },
    {
      id: 2,
      img: "https://via.placeholder.com/300x200",
      title: "Offer Help",
      description:
        "Offer help to others in your community. Whether you have a few hours to spare, a special skill to share, or just want to make a difference, we are here to help you find the support you need.",
    },
    {
      id: 3,
      img: "https://via.placeholder.com/300x200",
      title: "Connect with Others",
      description:
        "Connect with others who share your interests and values. Whether you are looking for a friend, a mentor, or a partner, we are here to help you find the support you need.",
    },
  ],
}

export const ThirdAboutContent: AboutContentInterface = {
  id: 1,
  title: "Need More Info",
  img: "https://via.placeholder.com/450x525",
  description:
    "If you have any questions or need more information, please feel free to contact us. We are here to help you find the support you need and to offer help to others. Join us today and start making a difference in your community!",
  cta: "Learn More",
}

export const ForthAboutContent: AboutContentInterface = {
  id: 1,
  title: "Your easey, trusted and powerful home for help",
  content: [
    {
      id: 1,
      img: "https://via.placeholder.com/300x200",
      title: "Find Help",
      description:
        "Find the help you need from people who care. Whether you need a ride to the grocery store, help with your homework, or someone to talk to, we are here to help you find the support you need.",
    },
    {
      id: 2,
      img: "https://via.placeholder.com/300x200",
      title: "Offer Help",
      description:
        "Offer help to others in your community. Whether you have a few hours to spare, a special skill to share, or just want to make a difference, we are here to help you find the support you need.",
    },
    {
      id: 3,
      img: "https://via.placeholder.com/300x200",
      title: "Make a Difference",
      description:
        "Make a difference in your community by helping others. Whether you volunteer your time, donate money, or raise awareness, we are here to help you find the support you need.",
    },
  ],
  cta: "Join us",
}

export const FifthAboutContent: AboutContentInterface = {
  id: 1,
  title: "Vision & Mission",
  description: `In a world often driven by scarcity and competition, the concept of an abundant mindset emerges as a refreshing and transformative approach to life. In our quest for success and happiness, we often overlook the simple yet profound truth that giving and receiving are intricately connected aspects of the human experience. Whether it's a kind gesture, a helping hand, or a heartfelt gift, the act of giving not only benefits others but also nourishes our own soul. Similarly, being open to receiving allows us to welcome the abundance that life has to offer and amplifies the blessings we receive.`,
}
