import { FC } from "react"
import CharityCategoryComponent from "./Administration/CharityCategoryComponent"

import CharityComponent from "./Administration/CharityComponent"
import CompanyComponent from "./Administration/Company/CompanyComponent"
import UserComponent from "./Administration/User/UserComponent"
import FaqComponent from "./Administration/FAQ/FaqComponent"
import ReportCategoryComponent from "./Administration/ReportCategoryComponent"
import ReportStatusComponent from "./Administration/ReportStatusComponent"
import SocialNetworkComponent from "./Administration/SocialNetworkComponent"
import CharityStatusComponent from "./Administration/CharityStatusComponent"
import CompanyStatusComponent from "./Administration/CompanyStatusComponent"
import RoleComponent from "./Administration/RoleComponent"

interface ModalSelectProps {
  section: string
  toggle: (sectionName: string) => void
}

const ModalSelect: FC<ModalSelectProps> = ({ section, toggle }) => {
  switch (section) {
    case "users":
      return <UserComponent show={true} toggle={toggle} />
    case "roles":
      return <RoleComponent show={true} toggle={toggle} />
    case "charityStatus":
      return <CharityStatusComponent show={true} toggle={toggle} />
    case "companyStatus":
      return <CompanyStatusComponent show={true} toggle={toggle} />

    case "foundationCategory":
      return <CharityCategoryComponent show={true} toggle={toggle} />

    // case "causeStatus":
    //   return <CauseStatusComponent show={true} toggle={toggle} />

    case "company":
      return <CompanyComponent show={true} toggle={toggle} />

    case "foundation":
      return <CharityComponent show={true} toggle={toggle} />
    case "faq":
      return <FaqComponent show={true} toggle={toggle} />
    case "reportCategory":
      return <ReportCategoryComponent show={true} toggle={toggle} />
    case "reportStatus":
      return <ReportStatusComponent show={true} toggle={toggle} />
    case "socialNetwork":
      return <SocialNetworkComponent show={true} toggle={toggle} />
    default:
      return <></>
  }
}

export default ModalSelect
