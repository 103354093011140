import { FC, ReactNode, useEffect, useState } from "react"
import Footer from "../Footer"
import NavbarLandingPage from "../Navbar/NavbarLandingPage"

interface LandingPageLayoutProps {
  children: ReactNode
}

const LandingPageLayout: FC<LandingPageLayoutProps> = ({ children }) => {
  const [fixedBottom, setFixedBottom] = useState(true)

  const excepLocations = [
    "/",
    "/foundations/*",
    "/organizations/*",
    "/about",
    "/causes/*",
    "/settings",
    "/profile",
    "/promotions/*",
    "/faq",
    "/admin/*",
  ]

  const validateLocations = () => {
    const currentPath = window.location.pathname

    const isExceptionPath = excepLocations.some(location => {
      // Use a wildcard check for "/organizations/*"
      if (location.includes("*")) {
        const baseLocation = location.replace("/*", "")
        return currentPath.startsWith(baseLocation)
      }
      return currentPath === location
    })

    setFixedBottom(!isExceptionPath)
  }

  useEffect(() => {
    validateLocations()
  }, [])

  return (
    <>
      <div className="d-flex flex-column min-vh-100 ">
        <NavbarLandingPage />
        {/* BODY */}
        {children}
        <Footer fixedBottom={fixedBottom} />
      </div>
    </>
  )
}

export default LandingPageLayout
