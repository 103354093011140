import { RoutesI } from "./common/interface/RouteIs"

export const Routes: RoutesI = {
  // GENERIC
  getin: {
    path: "/getin/:id",
    clean: "/getin/",
  },

  //Admin Routes
  AdminUsers: { path: "/admin/users" },
  AdminOrganizations: { path: "/admin/organizations" },

  //Landing Page Routes
  Home: { path: "/" },
  SignIn: { path: "/signin" },
  ForgotPassword: { path: "/forgotPassword" },
  Register: { path: "/register" },
  PasswordRecovery: {
    path: "/passwordRecovery/:id",
    clean: "/passwordRecovery/",
  },

  //User Routes
  NotFound: { path: "/error/NotFound" },
  ServerError: { path: "/examples/ServerError" },
  Chat: { path: "/Chat" },
  Settings: { path: "/settings", clean: "/settings/" },
  Reports: { path: "/reports/:id" },
  Profile: { path: "/profile" },

  Causes: { path: "/causes", clean: "/causes" },
  CauseDetails: { path: "/causes/:id", clean: "/causes/" },
  CauseSettings: { path: "/settings/cause", clean: "/settings/cause" },

  Foundations: { path: "/foundations", clean: "/foundations" },
  FoundationDetails: { path: "/foundations/:id", clean: "/foundations/" },
  FoundationSettings: {
    path: "/settings/foundation",
    clean: "/settings/foundation",
  },

  Faq: { path: "/faq", clean: "/faq" },

  Organizations: { path: "/organizations", clean: "/organizations" },
  OrganizationDetails: { path: "/organizations/:id", clean: "/organizations/" },
  OrganizationSettings: {
    path: "/settings/organization",
    clean: "/settings/organization",
  },

  Promotions: { path: "/promotions", clean: "/promotions" },
  PromotionDetails: { path: "/promotions/:id", clean: "/promotions/" },
  PromotionSettings: {
    path: "/settings/promotion",
    clean: "/settings/promotion",
  },

  About: { path: "/about", clean: "/about" },

  Search: { path: "/s" },
  Admin: { path: "/admin/:id?", clean: "/admin/" },
}
