import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import API from '../../../common/API'
import CausesGridItem from './CausesCategoryListItem'
import CausesCategoryListItem from './CausesCategoryListItem'

export interface CauseCategoryListResp {
  status: string;
  response: CauseCategory[];
  quantityOfPages: number;
  quantityOfRecords: number;
  page: number;
  quantityPerPage: number;
  needsLogin: boolean;
}

export interface CauseCategory {
  id: string;
  position: number | null;
  name: string;
  icon: string;
  created: Date | null;
}

const CausesCategoriesList = () => {
  const { t } = useTranslation('global')
  const [isLoading, setIsLoading] = useState(false)
  const [records, setRecords] = useState<CauseCategory[]>([])

  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      const data: AxiosResponse<CauseCategoryListResp> = await API.getAction("causeCategory/list")
      console.log(data);
      setRecords(data.data.response)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <div className="bg-white py-5">
        {!isLoading ? <Row>
          {records.map((item, index) => (
            <CausesCategoryListItem key={index} icon={item.icon} id={item.id} name={item.name} />
          ))}
        </Row> :
          <Row className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{`${t('loading')}...`}</span>
            </Spinner>
          </Row>}
    </div>
  )
}

export default CausesCategoriesList