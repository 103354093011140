import { Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import CausesItem from "./CausesItem"
import { useTranslation } from "react-i18next"

type CauseCategoryProps = {
  id: string
  name: string
  icon?: string
}

const CausesCategoryListItem = ({ id, name }: CauseCategoryProps) => {
  const { t } = useTranslation()

  // Array of 3 items
  const items = [
    {
      id: 1,
      name: "International Cause for Hope and Happiness",
      img: "https://picsum.photos/400/300?random=1",
      progress: 55,
      raised: 140,
      goal: 200,
      location: "Sto. Dom, Rep. Dom.",
    },
    {
      id: 2,
      name: "International Cause for Hope and Happiness",
      img: "https://picsum.photos/400/300?random=2",
      progress: 35,
      raised: 100,
      goal: 200,
      location: "Sto. Dom, Rep. Dom.",
    },
    {
      id: 3,
      name: "International Cause for Hope and Happiness",
      img: "https://picsum.photos/400/300?random=3",
      progress: 85,
      raised: 100,
      goal: 200,
      location: "Sto. Dom, Rep. Dom.",
    },
  ]

  return (
    <Row className="mt-3 categoryItem" md={12}>
      <h4 className="display-4 text-primary fw-bold mb-4">{name}</h4>
      <div className="d-flex gap-2 flex-wrap flex-md-nowrap justify-md-content-center w-100">
        {items.map((item, index) => (
          <CausesItem
            key={index}
            id={item.id}
            title={item.name}
            img={item.img}
            progress={item.progress}
            raised={item.raised}
            imgFooter={item.location}
          />
        ))}
      </div>
      <div className="py-2 w-100 d-flex justify-content-end">
        <Link to={`/causes/category/${id}`} className="btn btn-outline-primary">
          {`${t("All")} ${name}`}
          <i className="fas fa-arrow-right ms-2"></i>
        </Link>
      </div>
    </Row>
  )
}

export default CausesCategoryListItem
