import { useEffect, useState } from "react"
import "./HeroImage.css"

import Skeleton from "react-loading-skeleton"

const HeroImage = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000) // Cambia este valor para simular tiempos de carga diferentes

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="d-none d-md-block">
      {isLoading ? (
        <Skeleton height={400} className="w-100" />
      ) : (
        <div className="hero-image">
          {/* Reemplaza la imagen con un Skeleton mientras carga */}

          <div className="bg-white py-4 w-100 rounded-top-5 position-absolute bottom-0"></div>
        </div>
      )}
    </div>
  )
}

export default HeroImage
