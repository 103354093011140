import { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"

const OtherCompanies = props => {
  const [records, setRecords] = useState<any>([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const bindDataRemote = async () => {
    try {
      const request = await API.getAction("company/other?id=" + props.charityId)

      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (props.charityId) {
      bindDataRemote()
    }
  }, [props.charityId])

  return (
    <Card className="mb-4">
      <Card.Header className="poppins-bold">{t("Other companies")}</Card.Header>
      <Card.Body>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}...</span>
            </div>
          </div>
        )}
        <Row>
          {records &&
            records.length > 0 &&
            records.map((item, index) => (
              <Col
                key={index}
                className="clickable py-2"
                onClick={() => {
                  history.push(Routes.OrganizationDetails.clean + item.id)
                }}
              >
                <Row>
                  <Col className="text-center">
                    <img
                      src={`${Settings.BasePath}files/${item.logo}`}
                      alt={item.name}
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "0.25rem",
                      }}
                      loading="lazy"
                    />
                    <br />
                    <span className="text-truncate">{item.name}</span>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
        {}
      </Card.Body>
    </Card>
  )
}

export default OtherCompanies
