import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"
import NoData from "../../NoData"
import Timer from "../../Common/Timer"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"

interface PromotionsListProps {
  promotions?: any
  isLoading?: boolean
  changeIsLoading?: (status: boolean) => void
}

const PromotionsList = ({
  promotions,
  isLoading = false,
  changeIsLoading = (status: boolean) => {
    // Default empty function
  },
}: PromotionsListProps) => {
  const history = useHistory()
  const [records, setRecords] = useState<any>([])
  const redirectDetails = id => {
    history.push(Routes.OrganizationDetails.clean + id)
  }

  const bindDataRemote = async () => {
    try {
      changeIsLoading(true)
      const request = await API.getAction("company/list")
      if (request.data.status === "ok") {
        let data = request.data.response
        setRecords(
          data.map((item: any) => {
            let img = `${Settings.BasePath}files/${item.logo}`
            return {
              id: item.id,
              name: item.name,
              img: img,
              progress: 25,
              raised: 50,
              goal: 100,
              location: item.website,
            }
          })
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      changeIsLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  if (records.length === 0) return null
  return (
    <Row>
      {isLoading ? (
        // Muestra Skeletons mientras isLoading es true
        [1, 2, 3, 4, 5, 6].map((_, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            lg={4}
            className={`mb-6 clickableCard`}
            style={{ height: "18.75rem" }}
          >
            <div className="shadow bg-white d-flex justify-content-center align-items-center rounded w-100 h-100">
              <Skeleton width="100%" height="100%" />
            </div>
            <div className="ms-3 mt-2">
              <div className="d-flex flex-column mb-3">
                <Skeleton width={80} />
                <Skeleton width={120} />
              </div>
            </div>
          </Col>
        ))
      ) : records && records.length > 0 ? (
        records.map((item, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            lg={4}
            className={`mb-6 clickableCard`}
            style={{ height: "18.75rem" }}
            onClick={() => {
              redirectDetails(item.id)
            }}
          >
            <div className="shadow bg-white d-flex justify-content-center align-items-center rounded w-100 h-100">
              <img
                /* src={`${Settings.BasePath}files/${item.logo}`} */
                src={item.img}
                alt={item.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "0.25rem",
                }}
                loading="lazy"
              />
            </div>
            <div className="ms-3 mt-2">
              <div className="d-flex flex-column mb-3">
                <b className="text-muted">
                  <Timer deadline="2024-12-31T23:59:59" />
                </b>
                <b className="text-primary">{item.name}</b>
              </div>
              {/* <button className="btn bg-violetblue effect-bg-violetblue text-white w-100">
                Participate
              </button> */}
            </div>
          </Col>
        ))
      ) : (
        <NoData />
      )}
    </Row>
  )
}

export default PromotionsList
