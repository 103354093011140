import { useEffect, useState } from "react"
import { Accordion, Container, Row } from "react-bootstrap"
import API from "../../common/API"
import { faqI } from "../../common/interface/Faq.interface"
import ButtonLoadMore from "../Common/ButtonLoadMore"
import { PaginationI } from "../../common/interface/Pagination.interface"
import Loading from "../Loading"
import { useTranslation } from "react-i18next"

const FaqContainer = () => {
  const { t } = useTranslation()

  const [faq, setFaq] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginationState, setPaginationState] = useState<PaginationI>({
    page: 0,
    quantityOfPages: 1,
    quantityOfRecords: 3,
    quantityPerPage: 0,
    currentQuantity: 10,
    defaultIncrease: 10,
  })

  const increaseCurrentQuantity = () => {
    setPaginationState(prevState => ({
      ...prevState,
      currentQuantity: prevState.currentQuantity + prevState.defaultIncrease,
    }))
  }

  const requestFAQ = async () => {
    try {
      setLoading(true)
      let queryString = `name=${""}&Quantity=${10}&Page=${0}`
      let requestAPI = await API.getAction("faq/list?" + queryString)

      setFaq(requestAPI.data.response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    requestFAQ()
  }, [])

  return (
    <Container className="mt-5">
      <Row>
        <div className="text-center mb-3">
          <h1 className="text-primary display-1">{t("FAQ")}</h1>
          <span className="text-muted fs-2">
            {t("If you cant find what you are looking for, you can write us")}
          </span>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Loading />
          </div>
        ) : (
          <>
            <Accordion flush>
              {faq.map((item: faqI, index) => (
                <Accordion.Item key={index} eventKey={`${index}`}>
                  <Accordion.Header className="bg-light">
                    {t(item.title)}
                  </Accordion.Header>
                  <Accordion.Body className="bg-light-subtle">
                    {t(item.content)}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <div className="d-flex justify-content-center">
              <ButtonLoadMore
                increaseCurrentQuantity={increaseCurrentQuantity}
                paginationState={paginationState}
              />
            </div>
          </>
        )}
      </Row>
    </Container>
  )
}

export default FaqContainer
