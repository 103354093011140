import { Col, Row } from "react-bootstrap"
import CausesCategoriesGrid from "./CausesList/CausesCategoriesGrid"
import { useTranslation } from "react-i18next"
import CausesCategoriesList from "./CausesList/CausesCategoriesList"

const CausesContainer = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Col md={6} xs={12}>
        <div>
          <h1 className="text-primary display-1">
            {t("Browser cause by category")}
          </h1>
        </div>
      </Col>
      <Col md={7} xs={12}>
        <span className="text-muted fs-2">
          {t(
            "People globally are fundraising for the causes they care about deeply."
          )}
        </span>
      </Col>
      <Row>
        <Col xs={12}>
          <CausesCategoriesGrid />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CausesCategoriesList />
        </Col>
      </Row>
    </div>
  )
}

export default CausesContainer
