import { useFormik } from "formik"
import * as yup from "yup"
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Accordion, Card, Col, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import API from "../../../common/API"
import { CharityI } from "../../../common/interface/CharityI"
import { t } from "i18next"
import Pagination from "../../core/Pagination"
import Skeleton from "react-loading-skeleton"

interface CharitiesFilterProps {
  setCharities: Dispatch<SetStateAction<CharityI[] | undefined>>
  toggleLoading: (value: boolean) => void
  isLoading: boolean
  children: ReactNode
}

const validationSchema = yup.object({})

const CharitiesFilter: FC<CharitiesFilterProps> = ({
  setCharities,
  toggleLoading,
  isLoading,
  children,
}) => {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [quantity, setQuantity] = useState(10)

  const [categories, setCategories] = useState<any>([])

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }

  const bindDataRemote = async (obj: any) => {
    try {
      toggleLoading(true)

      let filterFields = obj ? `name=${obj.name}&` : ""

      let queryString = `${filterFields}Quantity=${quantity}&Page=${page}`

      let requestAPI = await API.getAction("charity/list?" + queryString)

      if (requestAPI.status === 200) {
        setTotalPages(requestAPI.data.quantityOfPages)
        setCharities(requestAPI.data.response)
      } else {
        throw new Error("Oops, that's an error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    } finally {
      toggleLoading(false)
    }
  }

  const bindDataCategoriesRemote = async () => {
    try {
      const data: any = await API.getAction("CharityCategory/list")
      if (data.data.status === "ok") {
        setCategories(data.data.response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (page) {
      bindDataRemote(null)
    }
  }, [page])

  useEffect(() => {
    bindDataCategoriesRemote()
    bindDataRemote(null)
  }, [])

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: bindDataRemote,
  })

  return (
    <Row>
      <Col xs={12} md={4} lg={3}>
        <Accordion defaultActiveKey={"0"} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header style={{ zIndex: "1" }}>
              <h4 className="text-primary">
                {isLoading ? <Skeleton width={100} /> : t("Filter")}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <label>
                          {isLoading ? <Skeleton width={60} /> : t("Search")}
                        </label>
                        {isLoading ? (
                          <Skeleton height={38} />
                        ) : (
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        )}
                      </Col>
                      <Col xs={12} className="mt-3">
                        <label>
                          {isLoading ? <Skeleton width={80} /> : t("Category")}
                        </label>
                        {isLoading ? (
                          <Skeleton count={4} />
                        ) : (
                          <ul className="list-group">
                            {categories.map((category: any, index: number) => (
                              <li
                                key={index}
                                className="list-group-item d-flex align-items-center clickable"
                              >
                                <i className={`me-4 ${category.icon}`}></i>
                                {category.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="btn btn-primary w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Skeleton width={60} />
                      ) : (
                        <>
                          <i className="fa fa-filter me-2"></i>
                          {t("Filter")}
                        </>
                      )}
                    </button>
                  </Card.Footer>
                </Card>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col xs={12} md={8} lg={9}>
        {children}
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <>
            <Card>
              <Card.Body className="p-1 pt-2">
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  onPageChanged={onPageChanged}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </Col>
    </Row>
  )
}

export default CharitiesFilter
