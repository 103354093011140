import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"

const AffiliatedCompanies = props => {
  const [records, setRecords] = useState<any>([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const bindDataRemote = async () => {
    try {
      const request = await API.getAction(
        "charity/relatedCompanies?id=" + props.charityId
      )

      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.charityId) {
      bindDataRemote()
    }
  }, [props.charityId])

  return (
    <Card className="mb-4">
      <Card.Header className="poppins-bold">
        {t("Affiliated Companies")}
      </Card.Header>
      <Card.Body>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}...</span>
            </div>
          </div>
        )}
        <Row>
          {records &&
            records.length > 0 &&
            records.map((item, index) => (
              <Col
                key={index}
                className="clickable py-2"
                xs={12}
                md={6}
                onClick={() => {
                  history.push(Routes.OrganizationDetails.clean + item.id)
                }}
              >
                <Row>
                  <Col className="col-auto">
                    <img
                      src={`${Settings.BasePath}files/${item.logo}`}
                      alt={item.name}
                      style={{
                        width: "32px",
                        maxHeight: "32px",
                        objectFit: "cover",
                        borderRadius: "0.25rem",
                      }}
                      loading="lazy"
                    />
                  </Col>
                  <Col>
                    <span>{item.name}</span>
                    <br />
                    <small className="text-primary me-2">
                      {t("IBBs")} 250,000.00
                    </small>
                    <br />
                    <small className="text-primary me-2">
                      {t("Next Raffle")}&nbsp;
                      <Timer deadline={"2024-12-31T23:59:59"} />
                    </small>
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
        {}
      </Card.Body>
    </Card>
  )
}

export default AffiliatedCompanies
