import { Container } from "react-bootstrap"
import CausesContainer from "../../components/CausesComponents/CausesContainer"

const CausesPage = () => {
  return (
  <Container>
    <CausesContainer />
  </Container>
  )
}

export default CausesPage
