import { PromotionI } from "../interface/Promotions.interface"

export const promotions: PromotionI[] = [
  {
    id: "1",
    name: "Promo 1",
    description: "Descuento en productos electrónicos",
    logo: "https://example.com/logo1.png",
    PromotionStatusId: "1",
    created: "2024-09-01T12:00:00Z",
    phone: "123-456-7890",
    website: "https://promo1.com",
    PromotionStatus: {
      id: "1",
      name: "Active",
    },
    ownerId: "101",
    owner: {
      id: "101",
      firstName: "John",
      lastName: "Doe",
      pic: "https://example.com/pic101.png",
    },
  },
  {
    id: "2",
    name: "Promo 2",
    description: "2x1 en comidas rápidas",
    logo: "https://example.com/logo2.png",
    PromotionStatusId: "2",
    created: "2024-08-15T08:30:00Z",
    phone: "123-456-7891",
    website: "https://promo2.com",
    PromotionStatus: {
      id: "2",
      name: "Inactive",
    },
    ownerId: "102",
    owner: {
      id: "102",
      firstName: "Jane",
      lastName: "Smith",
      pic: "https://example.com/pic102.png",
    },
  },
  {
    id: "3",
    name: "Promo 3",
    description: "Descuento en ropa de verano",
    logo: "https://example.com/logo3.png",
    PromotionStatusId: "1",
    created: "2024-07-25T09:45:00Z",
    phone: "123-456-7892",
    website: "https://promo3.com",
    PromotionStatus: {
      id: "1",
      name: "Active",
    },
    ownerId: "103",
    owner: {
      id: "103",
      firstName: "Carlos",
      lastName: "García",
      pic: "https://example.com/pic103.png",
    },
  },
  {
    id: "4",
    name: "Promo 4",
    description: "Descuento en tecnología",
    logo: "https://example.com/logo4.png",
    PromotionStatusId: "3",
    created: "2024-09-05T10:00:00Z",
    phone: "123-456-7893",
    website: "https://promo4.com",
    PromotionStatus: {
      id: "3",
      name: "Expired",
    },
    ownerId: "104",
    owner: {
      id: "104",
      firstName: "Anna",
      lastName: "Martínez",
      pic: null,
    },
  },
  {
    id: "5",
    name: "Promo 5",
    description: "30% de descuento en accesorios",
    logo: "https://example.com/logo5.png",
    PromotionStatusId: "2",
    created: "2024-06-18T15:20:00Z",
    phone: "123-456-7894",
    website: "https://promo5.com",
    PromotionStatus: {
      id: "2",
      name: "Inactive",
    },
    ownerId: "105",
    owner: {
      id: "105",
      firstName: "Luis",
      lastName: "Hernández",
      pic: "https://example.com/pic105.png",
    },
  },
  {
    id: "6",
    name: "Promo 6",
    description: "Envío gratuito en todas las compras",
    logo: "https://example.com/logo6.png",
    PromotionStatusId: "1",
    created: "2024-07-05T11:10:00Z",
    phone: "123-456-7895",
    website: "https://promo6.com",
    PromotionStatus: {
      id: "1",
      name: "Active",
    },
    ownerId: "106",
    owner: {
      id: "106",
      firstName: "Emily",
      lastName: "Lopez",
      pic: "https://example.com/pic106.png",
    },
  },
  {
    id: "7",
    name: "Promo 7",
    description: "15% de descuento en calzado deportivo",
    logo: "https://example.com/logo7.png",
    PromotionStatusId: "3",
    created: "2024-08-01T16:00:00Z",
    phone: "123-456-7896",
    website: "https://promo7.com",
    PromotionStatus: {
      id: "3",
      name: "Expired",
    },
    ownerId: "107",
    owner: {
      id: "107",
      firstName: "David",
      lastName: "Pérez",
      pic: "https://example.com/pic107.png",
    },
  },
  {
    id: "8",
    name: "Promo 8",
    description: "Descuento en entradas a eventos",
    logo: "https://example.com/logo8.png",
    PromotionStatusId: "1",
    created: "2024-09-10T12:15:00Z",
    phone: "123-456-7897",
    website: "https://promo8.com",
    PromotionStatus: {
      id: "1",
      name: "Active",
    },
    ownerId: "108",
    owner: {
      id: "108",
      firstName: "Sofia",
      lastName: "González",
      pic: null,
    },
  },
]
