import React, { FC } from "react"
import { dateFormater } from "../../common/date-formater"
import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"

interface HeaderDetailsProps {
  title: string | undefined
  time: string | undefined
  category?: string
}

const HeaderDetails: FC<HeaderDetailsProps> = ({
  title,
  time,
  category = "",
}) => {
  const { t } = useTranslation()
  return (
    <>
      <h1 className="text-primary mb-0 poppins-medium d-inline">{title}</h1>
      <button className="btn btn-clean ms-2">
        <i className="fas fa-edit"></i>
      </button>
      <Dropdown className="d-inline ms-2">
        <Dropdown.Toggle className="btn btn-clean clickable">
          <i className="fa fa-ellipsis-v"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">
            {t("Manage Social Networks")}
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2">{t("Manage Photos")}</Dropdown.Item>
          <Dropdown.Item href="#/action-3">{t("Manage Videos")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <small className="text-muted poppins-medium">
        {dateFormater(time)} {category.length > 0 && <>&bull; {category}</>}
      </small>
    </>
  )
}

export default HeaderDetails
