import { useFormik } from "formik"
import * as yup from "yup"
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Accordion, Card, Col, Form, Row } from "react-bootstrap"
import API from "../../../common/API"
import { toast } from "react-toastify"
import { CompanyI } from "../../../common/interface/Companies.interface"
import Pagination from "../../core/Pagination"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"

interface CompaniesFilterProps {
  setCompanies: Dispatch<SetStateAction<CompanyI[] | undefined>>
  toggleLoading: (value: boolean) => void
  isLoading: boolean
  children: ReactNode
}

// Validation
const validationSchema = yup.object({})

const CompaniesFilter: FC<CompaniesFilterProps> = ({
  setCompanies,
  toggleLoading,
  isLoading,
  children,
}) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [quantity] = useState(10)

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }

  const bindDataRemote = async obj => {
    try {
      toggleLoading(true)

      let filterFields = obj ? `name=${obj.name}&` : ""
      let queryString = `${filterFields}Quantity=${quantity}&Page=${page}`

      let requestAPI = await API.getAction("company/list?" + queryString)

      if (requestAPI.status === 200) {
        setTotalPages(requestAPI.data.quantityOfPages)
        setCompanies(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    } finally {
      toggleLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote(null)
  }, [page])

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: bindDataRemote,
  })

  return (
    <Row>
      <Col xs={12} md={4} lg={3}>
        <Accordion defaultActiveKey={"0"} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header style={{ zIndex: "1" }}>
              <h4 className="text-primary">
                {isLoading ? <Skeleton width={100} /> : t("Filter")}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <label>
                          {isLoading ? <Skeleton width={80} /> : t("Search")}
                        </label>
                        {isLoading ? (
                          <Skeleton height={38} />
                        ) : (
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="btn btn-primary w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Skeleton width={60} />
                      ) : (
                        <>
                          <i className="fa fa-filter me-2"></i>
                          {t("Filter")}
                        </>
                      )}
                    </button>
                  </Card.Footer>
                </Card>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col xs={12} md={8} lg={9}>
        {children}
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <>
            <Card>
              <Card.Body className="p-1 pt-2">
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  onPageChanged={onPageChanged}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </Col>
    </Row>
  )
}

export default CompaniesFilter
