import { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { CharityI } from "../../../common/interface/CharityI"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"
import NoData from "../../NoData"
import Settings from "../../../common/Settings"

interface CharitiesProps {
  charities: CharityI[] | undefined
}

const CharitiesList: FC<CharitiesProps> = ({ charities }) => {
  const history = useHistory()

  const redirectDetails = id => {
    history.push(Routes.FoundationDetails.clean + id)
  }
  return (
    <Row>
      {charities && charities.length > 0 ? (
        charities.map((item, index) => (
          <Col
            xs={12}
            md={6}
            lg={4}
            key={index}
            className={`mb-6 clickableCard`}
            style={{ height: "18.75rem" }}
            onClick={() => {
              redirectDetails(item.id)
            }}
          >
            <div className="shadow bg-white d-flex justify-content-center align-items-center rounded w-100 h-100">
              <img
                src={`${Settings.BasePath}files/${item.logo}`}
                alt={item.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "0.25rem",
                }}
                loading="lazy"
              />
            </div>
            <div className="ms-3 mt-2">
              <b>{item.name}</b>
            </div>
          </Col>
        ))
      ) : (
        <NoData />
      )}
    </Row>
  )
}

export default CharitiesList
