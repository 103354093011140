import React, { useEffect, useState } from "react"

import LinkSeeMore from "../Common/LinkSeeMore"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { Row } from "react-bootstrap"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import Timer from "../Common/Timer"
import { Routes } from "../../routes"
import { useHistory } from "react-router"
const CompaniesViewList = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  }
  const bindDataRemote = async () => {
    try {
      changeIsLoading(true)
      const request = await API.getAction("company/list")
      if (request.data.status === "ok") {
        let data = request.data.response
        setRecords(
          data.map((item: any) => {
            let img = `${Settings.BasePath}files/${item.logo}`
            return {
              id: item.id,
              name: item.name,
              img: img,
              progress: 25,
              raised: 50,
              goal: 100,
              location: item.website,
            }
          })
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      changeIsLoading(false)
    }
  }

  const changeIsLoading = (status: boolean) => setIsLoading(status)

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <div className="container">
      {/* Companies */}
      <h4 className="display-4 text-primary fw-bold mb-4">
        {isLoading ? <Skeleton width={200} /> : t("Companies")}
      </h4>
      <Row className="">
        <Slider {...settings}>
          {records.map((item, index) => {
            if (index === 0) return
            return (
              <div
                key={index}
                onClick={() => {
                  history.push(Routes.OrganizationDetails.clean + item.id)
                }}
                className="mx-2 clickable bg-light p-2 text-truncate"
              >
                <img
                  src={item.img}
                  alt={item.name}
                  style={{
                    height: "160px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  className="img-fluid img-thumbnail"
                />

                <Timer deadline="2024-12-31T23:59:59" />
                <br />

                <span className="text-primary">{item.name}</span>
              </div>
              // <CausesItem
              //   key={index}
              //   id={item.id}
              //   title={item.name}
              //   img={item.img}
              //   customHeight="160"
              //   progress={item.progress}
              //   raised={item.raised}
              //   imgFooter={item.location}
              //   customWidth="250"
              // />
            )
          })}
        </Slider>
      </Row>
      <div className="my-4" />
      {/* <PromotionsList changeIsLoading={changeIsLoading} isLoading={isLoading} /> */}
      {isLoading ? (
        <Skeleton width={150} height={40} className="d-block mx-auto" />
      ) : (
        <LinkSeeMore
          route="/companies"
          text={t("All Companies")}
          positionButton="center"
        />
      )}
    </div>
  )
}

export default CompaniesViewList
