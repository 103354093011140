import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"

type CauseCategoryProps = {
  id: string
  name: string
  icon: string
}

const CausesCategoryGridItem = ({ icon, id, name }: CauseCategoryProps) => {
  return (
    <Col className="mt-3 categoryItem" xs={6} md={4} xl={2}>
      <Link to={`/causes/category/${id}`}>
        <div className="rounded  align-middle text-center p-5 clickable bg-primary-light">
          <i className={`fa-3x ${icon}`} style={{ color: "#9C77C0" }}></i>
        </div>
        <div className="mt-2 text-center">
          <p>{name}</p>
        </div>
      </Link>
    </Col>
  )
}

export default CausesCategoryGridItem
