import { Button, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import HeaderDetails from "../../Common/HeaderDetails"
import { confirmAlert } from "react-confirm-alert"
import { useState } from "react"
import ActionCard from "../../CharitiesComponents/CharityDetails/ActionCard"
import OtherList from "../../Common/OtherList/OtherList"
import { useTranslation } from "react-i18next"

interface RouteParams {
  id: string
}
const PromotionDetails = () => {
  const { t } = useTranslation()

  const { id } = useParams<RouteParams>()

  const [showCloseCause, setShowCloseCause] = useState(false)

  const toggleCloseCause = () => setShowCloseCause(!showCloseCause)

  const warningCloseCause = () => {
    confirmAlert({
      closeOnClickOutside: false,
      title: "Are you sure you want to close this cause?",

      customUI: ({ title, message, onClose }) => (
        <Container
          className="bg-white py-6 px-5 rounded-5 "
          style={{ width: "33rem" }}
        >
          <Row>
            <Col xs={12}>
              <h4 className="poppins-medium color-darkblue">{title}</h4>
            </Col>
            <Col xs={12} className="mt-4">
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="mx-2"
                  onClick={onClose}
                  style={{
                    borderColor: "#6B21A8",
                    color: "#6B21A8",
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  className="mx-2"
                  onClick={async () => {
                    console.log("COSAS!")
                    /* await API.getAction(
                                      props.deleteRecordPath + "?id=" + item.id
                                    )
                                    await getRecords()
                                    setRecord(null)
                                    onClose() */
                    toggleCloseCause()
                    onClose()
                  }}
                  style={{
                    backgroundColor: "#6B21A8",
                    borderColor: "#6B21A8",
                  }}
                >
                  Yes, close
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      ),
    })
  }

  return (
    <>
      {/* {showCloseCause && (
        <CloseMessage show={showCloseCause} toggle={toggleCloseCause} />
      )} */}
      <Container className="py-5">
        <Row>
          <Col xs={12}>
            <HeaderDetails
              title="International Cause for Hope and Happiness"
              time="2024-11-11"
            />
          </Col>
          <Col xs={12} md={8} className="mt-4">
            <Row>
              <Col xs={12}>
                <div
                  style={{ height: "26rem", width: "100%" }}
                  className="clickableCard"
                >
                  <div
                    className="shadow bg-white d-flex justify-content-center align-items-center rounded w-100 h-100"
                    style={{
                      backgroundImage: `url(https://picsum.photos/224/224?random=${id})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <h5 className="text-white">224x224</h5>
                  </div>
                </div>
              </Col>
              <Col xs={12} className="mt-3">
                <div
                  className="d-inline-flex align-items-center p-2 rounded-pill"
                  style={{ backgroundColor: "#f8ebfe" }}
                >
                  <img
                    src={`https://picsum.photos/40/40?random=${id}`}
                    className="rounded-circle me-3"
                    alt="User Image"
                  />
                  <span>
                    <a>
                      <b className="text-primary">Karla Martinez</b>
                    </a>{" "}
                    organized this Promotion
                  </span>
                </div>
              </Col>
              <Col xs={12} className="mt-4 ">
                <div>
                  <h5 className="poppins-medium">
                    Korem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h5>
                  <p>
                    Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget Ibbundance velit, sit amet feugiat lectus.
                    Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget Ibbundance velit, sit amet feugiat lectus.
                    <br />
                    <br />
                    Vorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas eget Ibbundance velit, sit amet feugiat lectus.
                  </p>
                  <span>
                    {t("Do you have any questions? Visit our")}{" "}
                    <a className="text-primary">
                      <b>{t("FAQ question")}</b>
                    </a>{" "}
                  </span>
                </div>
                {/* <div className="mt-3">
                  <hr className="custom-hr" />
                  <CauseUpdates />
                  <hr className="custom-hr" />
                </div> */}
                <div className="mt-3 d-flex align-items-end">
                  <div>
                    <span className="text-secondary opacity-8 poppins-medium">
                      {t("Website")}:{" "}
                    </span>
                    <a
                      href="#"
                      className="text-primary"
                      style={{ textDecoration: "underline" }}
                    >
                      www.google.com
                    </a>
                  </div>
                </div>
                <div
                  className="my-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => console.log("Alguna Accion para reportar")}
                >
                  <span>
                    <i className="fa-solid fa-circle-exclamation" />{" "}
                    {t("Report this promotion")}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="mt-4">
            <Row>
              <ActionCard
                time="23d:23h:59m:59s"
                moneyCurrent={17500}
                isPromotion={true}
              />
              {/* <div className="ms-3 mt-3">
                <h6 className="poppins-medium" style={{ color: "#a48eba" }}>
                  {t("Menu administrator")}
                </h6>
                <div className="ms-3">
                  <div
                    className="my-4 custom-select"
                    onClick={() => {
                      console.log("EDIT PROFILE")
                    }}
                  >
                    <i className="fa-solid fa-pencil" />{" "}
                    <span className="ms-2">{t("Edit Cause")}</span>
                  </div>
                  <div className="custom-select">
                    <i className="fa-solid fa-user-group" />{" "}
                    <span className="ms-2">{t("User Administrator")}</span>
                  </div>
                  <div className="my-4 custom-select">
                    <i className="fa-solid fa-file-lines" />{" "}
                    <span className="ms-2">{t("Add Update")}</span>
                  </div>
                  <div
                    className="custom-select"
                    onClick={() => {
                       toggleCloseCause()
                      warningCloseCause()
                    }}
                  >
                    <i className="fa-solid fa-xmark" />{" "}
                    <span className="ms-2">{t("Close Cause")}</span>
                  </div>
                </div>
              </div> */}
            </Row>
          </Col>
          <Col xs={12}>
            <OtherList
              endpointName="promotions"
              idEntity={id}
              name="Another promotions"
              btnRouteName=""
              changePageBtn={true}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PromotionDetails
