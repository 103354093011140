import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  FifthAboutContent,
  ForthAboutContent,
  MainContentAbout,
  SecondAboutContent,
  ThirdAboutContent,
} from "../../common/mock/About.mock"
import { useTranslation } from "react-i18next"

const AboutContainer = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const handleNavigate = route => {
    history.push(route)
  }

  return (
    <div className="w-100 py-5">
      <header className="mb-4">
        <h1 className="display-2 text-primary">{MainContentAbout.title}</h1>
      </header>
      <Row className="flex-column-reverse flex-md-row mb-4 mb-md-2 w-100">
        <Col md={6} xs={12} className="mb-4">
          <p className="text-lg mb-4 mb-md-2">{MainContentAbout.description}</p>
          <div className="d-grid d-md-block">
            <Button
              variant="primary"
              onClick={() => handleNavigate("/signin")}
              size="lg"
            >
              {MainContentAbout.cta}
            </Button>
          </div>
        </Col>
        <Col md={6} xs={12} className="mb-4 mb-md-2 text-end">
          <img
            src={MainContentAbout.img}
            alt="placeholder"
            className="rounded-4 shadow-sm"
          />
        </Col>
      </Row>
      <Row className="mb-4 w-100">
        <Col md={12} className="mb-2">
          <h2 className="display-3">{SecondAboutContent.title}</h2>
          <hr />
        </Col>
        {SecondAboutContent.content?.map(item => (
          <Col md={4} key={item.id} xs={12} className="mb-4 mb-md-2">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </Col>
        ))}
      </Row>
      <Row className="mb-2 w-100">
        <Col md={12} className="mb-2">
          <h2 className="display-3">{ThirdAboutContent.title}</h2>
          <hr />
        </Col>
        <Col md={5} xs={12} className="mb-4 mb-md-2">
          <img
            src={ThirdAboutContent.img}
            alt="placeholder"
            className="rounded-4 shadow-sm"
          />
        </Col>
        <Col md={7} xs={12} className="mb-4 mb-md-2">
          <p>{ThirdAboutContent.description}</p>
          <div className="d-grid d-md-block">
            <Button
              variant="outline-primary"
              onClick={() => handleNavigate("/home")}
              size="lg"
            >
              {ThirdAboutContent.cta}
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="mb-4 w-100">
        <Col md={12} className="mb-2">
          <h2 className="display-3">{ForthAboutContent.title}</h2>
          <hr />
        </Col>
        {ForthAboutContent.content?.map(item => (
          <Col key={item.id} md={4} xs={12} className="mb-4 mb-md-2">
            <Link to={"/About"}>
              <Card>
                <Card.Img src={item.img} alt="placeholder" className="mb-4" />
                <Card.Body>
                  <Card.Title className="text-center">{item.title}</Card.Title>
                  <p>{item.description}</p>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
        <Col md={12} className="pt-4">
          <div className="d-grid d-md-block">
            <Button
              variant="primary"
              onClick={() => handleNavigate("/signin")}
              size="lg"
            >
              {ForthAboutContent.cta}
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mb-4 w-100">
        <Col md={12}>
          <h2 className="display-3">{FifthAboutContent.title}</h2>
          <hr />
          <p>{FifthAboutContent.description}</p>
        </Col>
      </Row>
    </div>
  )
}

// <Link to={'/foundations'}>Click here</Link>

export default AboutContainer
