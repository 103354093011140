import HeroImage from "./HeroImage"
import PromotedCausesComponent from "./PromotedCausesComponent"
import { Container } from "react-bootstrap"

import FaqComponent from "./FaqComponent"
import CompaniesViewList from "./CompaniesViewList"
import TextAndSlideShowContainer from "./TextAndSlideShowContainer"

const HomeComponent = () => {
  return (
    <div className="bg-white">
      <HeroImage />
      <PromotedCausesComponent />

      <CompaniesViewList />

      <div className="py-2 py-md-4 py-lg-5" />

      <TextAndSlideShowContainer />

      <Container
        className="py-4 d-flex justify-content-center mb-4 separateSlideShow"
        style={{ marginTop: "400px" }}
      ></Container>
      <FaqComponent />
    </div>
  )
}

export default HomeComponent
