import { useFormik } from "formik"
import * as yup from "yup"

import { Dispatch, FC, SetStateAction, useEffect } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import API from "../../../common/API"
import { toast } from "react-toastify"
import { PaginationI } from "../../../common/interface/Pagination.interface"
import { PromotionI } from "../../../common/interface/Promotions.interface"
import { promotions } from "../../../common/mock/Promotions.mock"
import { useTranslation } from "react-i18next"

interface PromotionsFilterProps {
  setPromotions: Dispatch<SetStateAction<PromotionI[] | undefined>>
  paginationState: PaginationI
  updatePaginationState: (value: any) => void
  toggleLoading: (value: boolean) => void
}

//Validation
const validationSchema = yup.object({})

const PromotionsFilter: FC<PromotionsFilterProps> = ({
  setPromotions,
  paginationState,
  updatePaginationState,
  toggleLoading,
}) => {
  const { t } = useTranslation()

  const RequestListFilter = async obj => {
    try {
      toggleLoading(true)
      let queryString = `name=${""}&Quantity=${
        paginationState.currentQuantity
      }&Page=${paginationState.page}`

      let requestAPI = await API.getAction("company/list?" + queryString)

      if (requestAPI.status === 200) {
        /* setPromotions(requestAPI.data.response)
        updatePaginationState(requestAPI) */

        setPromotions(promotions)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    } finally {
      toggleLoading(false)
    }
  }

  useEffect(() => {
    RequestListFilter(null)
  }, [paginationState.currentQuantity])

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: RequestListFilter,
  })

  return (
    <div className="mt-3">
      <Form onSubmit={formik.handleSubmit}>
        <Row className="d-block">
          <Col xs={12}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa-solid fa-magnifying-glass" />
                </InputGroup.Text>
                <Form.Control
                  as={"input"}
                  type="search"
                  name="name"
                  placeholder={t("Write something") + "..."}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default PromotionsFilter
