import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Skeleton from "react-loading-skeleton"

const TextAndSlideShowContainer = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)

  // Simular la carga de datos
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000) // Cambia este valor para simular tiempos de carga diferentes

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="position-relative-change">
      <div className="content-wrapper bg-primary-light py-4 d-flex justify-content-center mt-4">
        <div className="mb-4">
          <div className="content-inner w-100 px-3 px-md-4 px-lg-2 pt-2 pt-md-4 pt-lg-5">
            <h2 className="subtitle fw-bold mb-4">
              {isLoading ? (
                <Skeleton width={300} height={30} />
              ) : (
                "The power of giving & The blessings of receiving"
              )}
            </h2>
            <p className="poppins-regular">
              <b>
                {isLoading ? <Skeleton width={200} /> : "The Power of Giving:"}
              </b>
              <br />
              {isLoading ? (
                <Skeleton count={3} />
              ) : (
                `Giving is a powerful expression of compassion, empathy, and generosity. 
                It transcends boundaries and fosters connections, creating ripple effects of positivity that extend far beyond the initial act...`
              )}
            </p>
            <p>
              <b>
                {isLoading ? (
                  <Skeleton width={200} />
                ) : (
                  "The Blessings of Receiving:"
                )}
              </b>
              <br />
              {isLoading ? (
                <Skeleton count={3} />
              ) : (
                `On the other hand, receiving with gratitude allows us to fully appreciate the abundance that surrounds us...`
              )}
            </p>
            <p>
              <b>
                {isLoading ? (
                  <Skeleton width={200} />
                ) : (
                  "Creating a Positive Flow of Abundance:"
                )}
              </b>
              <br />
              {isLoading ? (
                <Skeleton count={3} />
              ) : (
                `When we cultivate a mindset of giving and receiving, we create a positive flow of abundance that enriches every aspect of our lives...`
              )}
              <br />
              <b className="text-priamry">
                {isLoading ? (
                  <Skeleton width={250} />
                ) : (
                  `True abundance is not measured by what we possess but by what we give and receive with an open heart.`
                )}
              </b>
            </p>
            <p className="poppins-regular" style={{ cursor: "pointer" }}>
              {isLoading ? (
                <Skeleton width={300} />
              ) : (
                <>
                  Do you have any questions? Visit our{" "}
                  <Link to="/about">FAQ Question</Link>
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      {/* Slideshow / Video Section */}
      <div className="slideshow-wrapper mt-5">
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <Skeleton
              width={400} // Puedes ajustar el ancho del skeleton para que se asemeje al contenedor real
              height={300} // Ajusta la altura también según el tamaño del slideshow
              className="slideshow-skeleton rounded-5"
            />
          ) : (
            <div className="slideshow-container bg-dark rounded-5">
              <div className="d-flex justify-content-center align-items-center h-100">
                <h2 className="poppins-bold text-muted">Slideshow / Video</h2>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextAndSlideShowContainer
