import React, { Children, FC, ReactNode } from "react"
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap"

import { useFormik } from "formik"
import * as yup from "yup"
import { t } from "i18next"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

interface CloseMessageProps {
  show: boolean
  toggle: () => void
}

const validationSchema = yup.object({})

const CloseMessage: FC<CloseMessageProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  const SendCloseMessage = async () => {
    try {
      console.log("PRUEBA")
    } catch (error) {
      toast.error("Oops, thast a error?")
    } finally {
      toggle()
    }
  }

  const formik = useFormik({
    initialValues: { message: "" },
    validationSchema,
    onSubmit: SendCloseMessage,
  })

  return (
    <Modal show={show} onHide={toggle} centered size="lg">
      {/* <Modal.Dialog className="py-6 px-3">*/}
      <Form className="py-6 px-3" onSubmit={formik.handleSubmit}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="poppins-medium color-darkblue">
                {t("Close Message")}
              </h3>
              <p className="poppins-regular color-darkblue">
                {t("This message appear after you close the cause")}
              </p>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="message">
                <Form.Label>{t("Message")}:</Form.Label>
                <InputGroup>
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder={t("Talk about why this cause close")}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    rows={5}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-4">
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="mx-2"
                  onClick={toggle}
                  style={{
                    borderColor: "#6B21A8",
                    color: "#6B21A8",
                  }}
                >
                  {t("Back")}
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  className="mx-2"
                  type="submit"
                  style={{
                    backgroundColor: "#6B21A8",
                    borderColor: "#6B21A8",
                  }}
                >
                  {t("Save")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default CloseMessage
