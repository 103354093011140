import { Col, Container, Row } from "react-bootstrap"
import PromotionsList from "./PromotionsList/PromotionsList"
import { useState } from "react"
import Loading from "../Loading"
import { PaginationI } from "../../common/interface/Pagination.interface"
import ButtonLoadMore from "../Common/ButtonLoadMore"
import { PromotionI } from "../../common/interface/Promotions.interface"
import PromotionsFilter from "./PromotionsFilter/PromotionsFilter"
import { useTranslation } from "react-i18next"

const PromotionsContainer = () => {
  const { t } = useTranslation()

  const [promotions, setPromotions] = useState<PromotionI[] | undefined>([])
  const [paginationState, setPaginationState] = useState<PaginationI>({
    page: 0,
    quantityOfPages: 1,
    quantityOfRecords: 3,
    quantityPerPage: 0,
    currentQuantity: 10,
    defaultIncrease: 10,
  })

  const [loading, setLoading] = useState(false)

  const toggleLoading = (status: boolean) => setLoading(status)

  const increaseCurrentQuantity = () => {
    setPaginationState(prevState => ({
      ...prevState,
      currentQuantity: prevState.currentQuantity + prevState.defaultIncrease,
    }))
  }

  const updatePaginationState = (responseData: any) => {
    setPaginationState(prevState => ({
      ...prevState,
      page: responseData.data.page,
      quantityOfPages: responseData.data.quantityOfPages,
      quantityOfRecords: responseData.data.quantityOfRecords,
      quantityPerPage: responseData.data.quantityPerPage,
    }))
  }

  return (
    <Container className="py-5">
      <Row>
        <Col xs={9}>
          <div>
            <h1 className="text-primary display-1">
              {t("Browser all promotions")}
            </h1>
            {/* <span className="text-primary" style={{ fontSize: "4rem" }}>
                The companies we are partnered with
              </span> */}
          </div>
          {/* <span className="text-muted fs-2">
            People globally are fundraising for the causes they care about
            deeply.
          </span> */}
        </Col>
        <Col xs={12}>
          <PromotionsFilter
            setPromotions={setPromotions}
            paginationState={paginationState}
            updatePaginationState={updatePaginationState}
            toggleLoading={toggleLoading}
          />
        </Col>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Loading />
          </div>
        ) : (
          <>
            <Col xs={12} className="mt-4">
              <PromotionsList promotions={promotions} />
            </Col>
            <Col xs={12} className="my-3 d-flex justify-content-center">
              <ButtonLoadMore
                increaseCurrentQuantity={increaseCurrentQuantity}
                paginationState={paginationState}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  )
}

export default PromotionsContainer
