import {
  Navbar as NavBar,
  Nav,
  Container,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap"
import UserBtnComponent from "./UserBtnComponent"

import { Routes } from "../../../routes"
import { useHistory } from "react-router"
import { Link, NavLink } from "react-router-dom"
import Logo from "../Logo"
import NotificationsModal from "../Notification/NotificationsModal"
import { useState } from "react"
import { parsedUser } from "../../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"

const NavbarLandingPage = () => {
  const { t } = useTranslation()

  const [showNotifications, setShowNotifications] = useState(false)
  const user = parsedUser()
  const toggleNotifications = () => setShowNotifications(!showNotifications)

  const history = useHistory()
  return (
    <>
      {showNotifications && (
        <NotificationsModal
          show={showNotifications}
          toggle={toggleNotifications}
        />
      )}

      <NavBar bg="white" expand="lg" style={{ zIndex: "2" }}>
        <Container>
          <NavBar.Toggle aria-controls="basic-navbar-nav" />
          <NavBar.Offcanvas id="basic-navbar-nav">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{t("Menu")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <NavBar.Brand href="#" className="d-flex align-items-center">
                <Link to={Routes.Home.path}>
                  <div className="d-none d-lg-flex align-items-center">
                    <div>
                      <Logo
                        logoName="logo2"
                        className="d-inline-block align-top"
                        alt="ibbundance"
                        width={50}
                      />
                    </div>
                    <div className="ms-3">
                      <h2 className="text-logo poppins-semibold ">
                        Ibbundance
                      </h2>
                    </div>
                  </div>
                </Link>
              </NavBar.Brand>

              <Nav className="align-items-center ms-auto">
                <NavLink
                  to={Routes.Foundations.path}
                  className={"px-3 header-item mb-3 mb-lg-0 "}
                >
                  <i className="fa fa-search me-2"></i> {t("Search")}
                </NavLink>

                {/* <NavLink
                  to={Routes.Foundations.path}
                  className={isActive =>
                    `nav-link header-item ms-2  ${
                      isActive
                        ? "fw-bold bg-primary rounded text-white px-3"
                        : ""
                    }`
                  }
                >
                  {t("Foundations")}
                </NavLink> */}
                <NavLink
                  to={Routes.Organizations.path}
                  className={isActive =>
                    `nav-link header-item ms-2  ${
                      isActive
                        ? "fw-bold bg-primary rounded text-white px-3"
                        : ""
                    }`
                  }
                >
                  {t("Companies")}
                </NavLink>
                <NavLink
                  to={Routes.About.path}
                  className={isActive =>
                    `nav-link header-item ms-2  ${
                      isActive
                        ? "fw-bold bg-primary rounded text-white px-3"
                        : ""
                    }`
                  }
                >
                  {t("About")}
                </NavLink>
                {user && (
                  <>
                    <NavLink
                      to={"#"}
                      className="text-white d-inline me-lg-2 ms-lg-3"
                      onClick={() => toggleNotifications()}
                    >
                      <div className="user-avatar rounded bg-light rounded-5">
                        <i className="fa-regular fa-bell text-dark p-0 m-0 fs-6" />
                        <span
                          style={{
                            top: "35%",
                            left: "65.5%",
                            padding: "0.4rem",
                          }}
                          className="position-absolute translate-middle-y bg-danger border border-light rounded-circle d-none"
                        >
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                    </NavLink>

                    <NavDropdown
                      title={
                        <div className="user-avatar rounded bg-light rounded-5 ">
                          <i className="fa fa-cog text-dark p-0 m-0 fs-6" />
                          <span
                            style={{
                              top: "22%",
                              left: "72%",
                              padding: "0.4rem",
                            }}
                            className="position-absolute translate-middle bg-danger border border-light rounded-circle d-none"
                          >
                            <span className="visually-hidden">New alerts</span>
                          </span>
                        </div>
                      }
                      className="text-white d-inline"
                    >
                      {/* <NavDropdown.Item
                        as="button"
                        onClick={() => {
                          history.push(Routes.Admin.clean + "companies")
                        }}
                        className="header-item "
                      >
                        {t("Companies")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as="button"
                        onClick={() => {
                          history.push(Routes.Admin.clean + "foundations")
                        }}
                        className="header-item "
                      >
                        {t("Foundations")}
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        as="button"
                        onClick={() => {
                          history.push(Routes.Admin.path)
                        }}
                        className="header-item "
                      >
                        {t("Admin")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                <div className="me-lg-4 ms-2">
                  <UserBtnComponent />
                </div>
              </Nav>
            </Offcanvas.Body>
          </NavBar.Offcanvas>
          <NavBar.Brand className="d-block d-lg-none ">
            <Link to={Routes.Home.path}>
              <Logo
                logoName="logo2"
                className="d-inline-block align-top"
                alt="ibbundance"
                width={50}
              />
            </Link>
          </NavBar.Brand>
        </Container>
      </NavBar>
    </>
  )
}

export default NavbarLandingPage
