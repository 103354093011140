import { useState } from "react"
import { Updates } from "../../../common/mock/CauseUpdates.mock"
import { dateFormater } from "../../../common/date-formater"
import { useTranslation } from "react-i18next"

const CauseUpdates = () => {
  const [updates] = useState(Updates)
  const [seeAll, setSeeAll] = useState(false)

  const { t } = useTranslation()

  const sortedUpdates = [...updates].sort((a, b) => {
    const dateA = new Date(a.date).getTime()
    const dateB = new Date(b.date).getTime()
    return dateB - dateA
  })

  return (
    <>
      <h5 className="poppins-medium">
        {t("Updates")}({updates.length})
      </h5>
      {seeAll ? (
        sortedUpdates.map(item => (
          <div key={item.id} className="mb-3">
            <div className="text-primary">
              <b className=" poppins-bolder fs-6">{dateFormater(item.date)} </b>{" "}
              <small>
                {t("by")} {item.by}
              </small>
            </div>
            <p>{item.description}</p>
          </div>
        ))
      ) : (
        <div key={sortedUpdates[0].id} className="mb-3">
          <div className="text-primary">
            <b className=" poppins-bolder fs-6">
              {dateFormater(sortedUpdates[0].date)}
            </b>{" "}
            <small>
              {" "}
              {t("by")} {sortedUpdates[0].by}
            </small>
          </div>
          <p>{sortedUpdates[0].description}</p>
        </div>
      )}
      <button
        className="load-more-btn rounded fs-6"
        onClick={() => setSeeAll(!seeAll)}
      >
        {seeAll ? t("Show less") : t("Load more")}{" "}
        <i className="ms-2 fa-solid fa-chevron-down" />
      </button>
    </>
  )
}

export default CauseUpdates
