import { t } from "i18next"
import React, { useEffect, useState } from "react"

interface TimerProps {
  deadline: string // Fecha límite en formato ISO (YYYY-MM-DDTHH:mm:ss)
}

const Timer: React.FC<TimerProps> = ({ deadline }) => {
  const calculateTimeLeft = () => {
    const deadlineDate = new Date(deadline)

    if (isNaN(deadlineDate.getTime())) {
      // Si la fecha no es válida, devuelve null
      return null
    }

    const now = new Date().getTime() // Obtiene la fecha actual en milisegundos
    const difference = deadlineDate.getTime() - now // Diferencia en milisegundos

    if (difference <= 0) {
      return null // Si la fecha límite ya pasó, devolver null
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer) // Limpia el intervalo al desmontar el componente
  }, [deadline])

  if (!timeLeft) {
    return <div>{t("The time has expired or the date is invalid!")}</div>
  }

  return (
    <>
      {timeLeft.days}D {timeLeft.hours}H {timeLeft.minutes}m {timeLeft.seconds}s
    </>
  )
}

export default Timer
