import { ComponentType, FC } from "react"
import { Routes } from "../routes"

import ProfilePage from "./ProfilePage"

import ReportsPage from "./ReportsPage"
import SettingsPage from "./SettingsPage"

import AdminUsersPage from "./AdminUsersPage"
import AdminOrganizationPage from "./AdminOrganizationPage"
import LoginPage from "./LoginPage"
import RegisterPage from "./RegisterPage"
import ForgotPassword from "../components/Profile/ForgotPassword"
import HomePage from "./HomePage/HomePage"

import LandingPageLayout from "../components/Common/Layouts/LandingPageLayout"
import WithSidebarLayout from "../components/Common/Layouts/WithSidebarLayout"
import PasswordRecovery from "../components/Profile/PasswordRecovery"

import CompaniesPage from "./CompaniesPage/CompaniesPage"
import CompanyDetails from "../components/CompaniesComponents/CompaniesDetails/CompanyDetails"

import CharitiesPage from "./CharitiesPage/CharitiesPage"
import CharityDetails from "../components/CharitiesComponents/CharityDetails/CharityDetails"

import CausesPage from "./CausesPage/CausesPage"
import CausesDetails from "../components/CausesComponents/CausesDetails/CauseDetails"

import AboutPage from "./AboutPage/AboutPage"

import PromotionsPage from "./PromotionsPage/PromotionsPage"
import PromotionDetails from "../components/PromotionsComponents/PromotionsDetails/PromotionDetails"
import FaqPage from "./FaqPage/FaqPage"
import AdminPage from "./AdminPage/AdminPage"
/* import Error404 from "../components/Common/Error404"
 */
interface RouteDTO {
  path: string
  component: FC
  layout: ComponentType<any>
  typeRoute: "all" | "private" | "public"
  exact?: boolean
}

//ATENTION: typeRoute is "all, public and private".

const routesWithComponent: RouteDTO[] = [
  {
    path: Routes.Admin.path,
    component: AdminPage,
    layout: LandingPageLayout,
    typeRoute: "all",
    exact: true,
  },
  {
    path: Routes.Home.path,
    component: HomePage,
    layout: LandingPageLayout,
    typeRoute: "all",
    exact: true,
  },
  {
    path: Routes.Profile.path,
    component: ProfilePage,
    layout: LandingPageLayout,
    typeRoute: "private",
    exact: true,
  },

  {
    path: Routes.Reports.path,
    component: ReportsPage,
    layout: WithSidebarLayout,
    typeRoute: "private",
    exact: true,
  },

  {
    path: Routes.Settings.path,
    component: SettingsPage,
    layout: LandingPageLayout,
    typeRoute: "all",
    exact: true,
  },

  {
    path: Routes.AdminUsers.path,
    component: AdminUsersPage,
    layout: WithSidebarLayout,
    typeRoute: "private",
    exact: true,
  },
  {
    path: Routes.AdminOrganizations.path,
    component: AdminOrganizationPage,
    layout: WithSidebarLayout,
    typeRoute: "private",
    exact: true,
  },
  {
    path: Routes.SignIn.path,
    component: LoginPage,
    layout: WithSidebarLayout,
    typeRoute: "all",
    exact: true,
  },

  {
    path: Routes.ForgotPassword.path,
    component: ForgotPassword,
    layout: WithSidebarLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.PasswordRecovery.path,
    component: PasswordRecovery,
    layout: WithSidebarLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.Register.path,
    component: RegisterPage,
    layout: WithSidebarLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.Promotions.path,
    component: PromotionsPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.PromotionDetails.path,
    component: PromotionDetails,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.Organizations.path,
    component: CompaniesPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.OrganizationDetails.path,
    component: CompanyDetails,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.Foundations.path,
    component: CharitiesPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.FoundationDetails.path,
    component: CharityDetails,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.Causes.path,
    component: CausesPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.CauseDetails.path,
    component: CausesDetails,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
  {
    path: Routes.About.path,
    component: AboutPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },

  {
    path: Routes.Faq.path,
    component: FaqPage,
    layout: LandingPageLayout,
    typeRoute: "public",
    exact: true,
  },
]

export default routesWithComponent
