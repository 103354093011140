import React, { FC, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import API from "../../../common/API"
import CompaniesList from "../../CompaniesComponents/CompaniesList/CompaniesList"
import CharitiesList from "../../CharitiesComponents/CharitiesList/CharitiesList"
import NoData from "../../NoData"
import ButtonLoadMore from "../ButtonLoadMore"
import { PaginationI } from "../../../common/interface/Pagination.interface"
import PromotionsList from "../../PromotionsComponents/PromotionsList/PromotionsList"
import LinkSeeMore from "../LinkSeeMore"
import { useTranslation } from "react-i18next"

interface OtherListProps {
  idEntity: string
  name: string
  changePageBtn?: boolean
  btnRouteName: string
  endpointName: string
}

const OtherList: FC<OtherListProps> = ({
  idEntity,
  name,
  btnRouteName,
  changePageBtn = false,
  endpointName,
}) => {
  const { t } = useTranslation()
  const [others, setOthers] = useState([])
  const location = useLocation()

  const [paginationState, setPaginationState] = useState<PaginationI>({
    page: 0,
    quantityOfPages: 1,
    quantityOfRecords: 3,
    quantityPerPage: 0,
    currentQuantity: 3,
    defaultIncrease: 10,
  })

  const getOtherList = async () => {
    try {
      let queryString = `id=${idEntity}&name=${""}&Quantity=${
        paginationState.currentQuantity
      }&Page=${paginationState.page}`

      let requestAPI = await API.getAction(
        `${endpointName}/other?${queryString}`
      )

      if (requestAPI.status === 200) {
        setOthers(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }

      console.log(requestAPI)
    } catch (error) {
      toast.error(`${error}`)
    }
  }

  const selectComponentList = () => {
    switch (endpointName) {
      case "company":
        return <CompaniesList companies={others} />
      case "charity":
        return <CharitiesList charities={others} />
      case "promotion":
        return <PromotionsList promotions={others} />
      default:
        return <></>
    }
  }

  const increaseCurrentQuantity = () => {
    setPaginationState(prevState => ({
      ...prevState,
      currentQuantity: prevState.currentQuantity + prevState.defaultIncrease,
    }))
  }

  useEffect(() => {
    getOtherList()
  }, [location])

  return (
    <Row className="mt-3 categoryItem">
      <h4 className="display-4 text-primary fw-bold mb-4">{t(name)}</h4>

      {others.length > 0 ? selectComponentList() : <NoData />}

      <Col xs={12}>
        {changePageBtn ? (
          <LinkSeeMore
            route="/promotions"
            text={t("View another promotions")}
            positionButton="center"
          />
        ) : (
          <ButtonLoadMore
            increaseCurrentQuantity={increaseCurrentQuantity}
            paginationState={paginationState}
          />
        )}
      </Col>
    </Row>
  )
}

export default OtherList
